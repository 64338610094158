/**
 * Check if a form is valid
 * @param $ jQuery
 * @param form Form element
 * @param honey Honeypot value
 */
export const checkValid = ($: any, form: HTMLFormElement, honey: string) => {
  return new Promise((resolve, reject) => {
    if ($(form).parsley().isValid() && !(honey.length)) {
      resolve('success');
    } else {
      reject(new Error('not valid'));
    }
  });
};

/**
 * Throttle a function
 * @param callback Callback function
 * @param limit How often to call the callback
 */
export function _throttle(callback: Function, limit: number) {
  let waiting = false; // Initially, we're not waiting
  return function() { // We return a throttled function
    if (!waiting) { // If we're not waiting
      callback.apply(this, arguments); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(function() { // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  };
}

/**
 * Preload images
 * @param images Array of image paths
 */
export function preloadImages(images: string[]) {
  images.forEach((image) => {
    new Image().src = image;
  });
}

/**
 * Get all tabbable elements on the page
 * @returns All tabbable elements on the page
 */
export function getPageButtons() {
  return document.querySelectorAll(`
    select:not([tabindex="-1"]),
    input:not([tabindex="-1"]),
    textarea:not([tabindex="-1"]),
    button:not([tabindex="-1"]),
    a:not([tabindex="-1"]),
    iframe:not([tabindex="-1"])
  `);
}

const priceFormatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 2,
});

/**
 * Formats money as currency
 */
export function formatAsMoney(amount) {
  return priceFormatter.format(amount).replace(/\D00(?=\D*$)/, '');
}

/** Debounce taken from _.js */
export const debounce = function(func, wait, immediate?) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

/** Given a full shopify ID string, extract the numeric ID */
export function extractID(id) {
  const regex = /(\d+)/; // Match one or more digits

  const match = id.match(regex);

  if (match) {
    return match[0];
  } else {
    return null;
  }
}

/**
 * Search for and return the first number in a string
 * @param str The string to parse
 * @returns The first number in the string, or null if none found
 */
export function parseFirstNumber(str: string): number | null {
  const match = str.match(/-?\d+(\.\d+)?/);
  if (match) {
    return parseFloat(match[0]);
  }
  return null;
}

/**
 * Generate a sized Shopify image URL
 * @param url Shopify image URL
 * @param size Desired width of image in pixels
 * @returns New image URL with specified image width
 */
export function getSizedShopifyImageUrl(url: string, size: number) {
  if (!url) {
    return undefined;
  }

  // e.g. 'https://cdn.shopify.com/s/files/1/0721/9897/9877/files/M25807KN-0001_92ff8505-7b1d-4c98-8afe-fceebaaa5d37'
  const beginningOfUrl = url.substring(0, url.lastIndexOf('.'));
  // e.g. '.jpg?v=1694739373'
  const endOfUrl = url.substring(url.lastIndexOf('.'));

  const newUrl = `${beginningOfUrl}_${size}x${endOfUrl}`;
  // console.debug('newUrl', newUrl);

  return newUrl;
};

/**
 * Get a metafield value from a product and its variants
 * @param client Shopify client
 * @param productId Product ID
 * @param fieldName Name of the metafield to retrieve
 * @returns The product with metafield and the variants with metafields
 */
export async function getMetafields(client, productId: string, fieldName: string) {
  // TODO: Can we optimize this to query `products` instead so we can batch them?
  // const ids: string[] = productIds.map((id) => id.replace('gid://shopify/Product/', ''));
  // const idsQuery = `product_ids:${ids.join(',')}`;

  // Build a custom products query using the unoptimized version of the SDK
  const productQuery = client.graphQLClient.query((root) => {
    root.add('product', { args: {
      // first: productIds.length,
      // query: idsQuery,
      id: productId,
    } }, (product) => {
      // Add fields to be returned
      product.addConnection('variants', { args: { first: 50 } }, (variant) => {
        variant.add('sku');
        variant.add('metafield', { args: {
          namespace: 'custom',
          key: fieldName,
        } }, (metafield) => {
          metafield.add('value');
        });
      });
      product.add('metafield', { args: {
        namespace: 'custom',
        key: fieldName,
      } }, (metafield) => {
        // if you want more than one metafield
        // product.add('metafields', { args: { identifiers: [
        //   { namespace: 'custom', key: 'public_sku' },
        // ] } }, (metafield) => {
        metafield.add('value');
      });
    });
  });

  // Call the send method with the custom products query
  return client.graphQLClient.send(productQuery).then(({ model }) => {
    // const value = model.product.metafield?.value;
    // console.debug({ model, value });
    return model.product;
  });
}

export function getLineItemPublicSku(product, lineItem) {
  const variantId = lineItem.variant.id;
  const productVariant = product.variants.find((variant) => variant.id === variantId);
  const publicSku = productVariant.publicSku;
  return publicSku;
};

/**
 * Get a product from Shopify by handle, and include the public SKU metafields
 * @param client Shopify client
 * @param handle Product handle (optional - needs either this or productId)
 * @param productId Product ID (optional - needs either this or handle)
 * @returns The product (and its variants) with public SKU added
 */
export async function getProductWithPublicSku(
  { client, handle, productId } : { client: any, handle?: string, productId?: string }
) {
  let product = handle
    ? await client.product.fetchByHandle(handle)
    : await client.product.fetch(productId);

  const metafieldProduct = await getMetafields(client, product.id, 'public_sku');

  // Save a publicSKU on the product object, and then I'll have it when I need it
  product.publicSku = metafieldProduct.metafield?.value;
  product.variants.forEach((variant) => {
    const variantPublicSku = metafieldProduct.variants.find((v) => v.id === variant.id)?.metafield?.value;

    // If only a single variant exists (i.e. no variants), and it doesn't have a public SKU,
    // use the product's public SKU, because the virtual variant Shopify creates
    // doesn't auto-populate the variant's public SKU metafield
    if (product.variants.length === 1 && !variantPublicSku && product.publicSku) {
      variant.publicSku = product.publicSku;
    } else {
      // Fall back to regular variant SKU
      variant.publicSku = variantPublicSku || variant.sku;
    }
  });

  return product;
}
