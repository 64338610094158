import { displayErrorsObject } from './form-errors';
import Pristine from 'pristinejs';

/*
 * Add the class 'js-form' to the form to get it to submit and be validated using Pristine
 * Add a 'data-route' attribute to the form to specify where it will submit to (it gets added to '/form/')
 *	TODO: that route can be changed to whatever it needs to be and then just needs to be updated on line #72
 * Add a div.success-wrapper within the form for the success message
 * To specify the success message add the 'data-msg' attribute to the div.success-wrapper
 * (otherwise the message will default to ' Success!')
 * Add a div.error-wrapper within the form for any error messages
 */

/** Enable or disable a form's inputs */
function updateInputs(form, disable) {
  const inputs = form.querySelectorAll('input, textarea, button');
  inputs.forEach(function(input) {
    if (disable) {
      input.setAttribute('disabled', true);
    } else {
      input.removeAttribute('disabled', true);
    }
  });
}

function showSuccess(form) {
  const successWrapper = form.querySelector('.success-wrapper');
  if (!successWrapper) {
    return;
  }
  const successMsg = successWrapper.getAttribute('data-msg')
    ? successWrapper.getAttribute('data-msg')
    : 'Success!';

  const msgWrapper = document.createElement('span');
  msgWrapper.textContent = successMsg;
  successWrapper.appendChild(msgWrapper);

  successWrapper.focus();
}

function hideAllMsgs(form) {
  const successWrapper = form.querySelector('.success-wrapper');
  const errorsWrapper = form.querySelector('.errors-wrapper');

  if (successWrapper) {
    successWrapper.innerHTML = '';
  }

  if (errorsWrapper) {
    errorsWrapper.innerHTML = '';
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  const forms = [].slice.call(document.querySelectorAll('.js-form'));
  forms.forEach(function(form) {
    const pristine = new Pristine(form, {
      classTo: 'form__input',
      errorTextParent: 'form__input',
    });

    form.addEventListener('submit', async (e) => {
      e.preventDefault();

      hideAllMsgs(form);

      const valid = pristine.validate();

      // console.log(`valid: ${valid}`);

      if (valid) {
        const route = form.getAttribute('data-route');
        const formData = new FormData(form);
        updateInputs(form, true); // disable inputs

        // console.log(formData);

        try {
          const response = await fetch(`/api/forms/${route}`, {
            method: 'POST',
            credentials: 'same-origin',
            body: formData,
          });

          const json = await response.json();

          // Display any errors returned from the backend
          if (!response.ok) {
            displayErrorsObject(form, json.errors);
            return;
          }

          showSuccess(form);
          // ??? Leave fields disabled so the user doesn't submit the form twice?
          // updateInputs(form, false); // enable inputs
        } catch (error) {
          console.error(error);
          displayErrorsObject(form, { error: ['Error submitting form'] });
          updateInputs(form, false); // enable inputs
        }
      }
    });
  });
});
